<template>
    <div>
        <template v-if="invitations.length">
            <div style="height:91px; border-bottom:2px solid #D8D8D8" v-for="invitation in invitations" :key="invitation.id" class="dF aC" :class="$mq == 'sm' ? '':'px-4'">
                <a-col :span="$mq == 'sm' ? 10:5">
                    <div class="dF aC">
                        <template v-if="$mq != 'sm'">
                            <a-avatar class="mr-3" :size="30" v-if="invitation.createdBy && invitation.createdBy.avatar && invitation.createdBy.avatar != ''" :src="invitation.createdBy.avatar"></a-avatar>
                            <a-avatar class="mr-3" :size="30" v-else>{{`${invitation.createdBy.firstName[0]}${invitation.createdBy.lastName[0]}`}}</a-avatar>
                        </template>
                        <div style="font-weight:600">{{`${invitation.createdBy.firstName} ${invitation.createdBy.lastName}`}}</div>
                    </div>
                </a-col>
                <a-col v-if="$mq != 'sm'" :span="2">
                    <div v-if="invitation.role && invitation.role.name && invitation.role.name != ''">{{$getRoleName(invitation.role.name)}}</div>
                </a-col>
                <a-col v-if="$mq != 'sm'" :span="6">
                    <div v-if="invitation.createdBy && invitation.createdBy.email && invitation.createdBy.email != ''">{{invitation.createdBy.email}}</div>
                </a-col>
                <a-col :span="$mq == 'sm' ? 12:4">
                    <div style="font-weight:600" v-if="invitation.instance && invitation.instance.name && invitation.instance.name != ''">{{invitation.instance.name}}</div>
                </a-col>
                <a-col :span="$mq == 'sm' ? 3:7">
                    <div v-if="$mq != 'sm'" class="dF jE" style="gap: 20px">
                        <a-button type="danger" @click="decline(invitation)">DECLINE INVITATION</a-button>
                        <a-button type="primary" @click="accept(invitation)">ACCEPT INVITATION</a-button>
                    </div>
                    <div v-else>
                        <a-popover overlayClassName="popover-padding">
                            <template slot="content">
                                <div @click="accept(invitation)" class="popover-content">ACCEPT INVITATION</div>
                                <div @click="decline(invitation)" class="popover-content">DECLINE INVITATION</div>
                            </template>
                            <a-icon type="more" />
                        </a-popover>
                    </div>
                </a-col>
            </div>
        </template>
        <template v-else>
            <div class="align-center mt-5">
                <img style="width:100%; max-width:500px" src="@/assets/noinstance_found.svg" />
                <h3 class="mt-5">No Invitations</h3>
            </div>
        </template>
    </div>
</template>

<script>

export default {
    components:{},
	props:{
    },
    watch:{

    },
    computed:{
		invitations() {
			return this.$store.state.invitations
		}
    },
    data(){
        return {
            showConfirm:false,
            confirmLoading:false,
            confirm:{
                id:'',
                show:false,
                instance:{},
                role:{},
                email:'',
				declineToken:''
            },
        }
    },
    methods:{
        async accept({instance,role,id,email}){
            this.confirm = {instance,role,id,email}
            this.confirm.accept = true
			let self = this
			this.$confirm({
				title: 'Accepting Invitation',
				content: h => <div class='mt-4'>This will accept the invitation to <strong>{instance.name}</strong> with the role of {this.$getRoleName(role.name)}. Are you sure?</div>,
				okText: 'OK',
				okType: 'danger',
				cancelText: 'Cancel',
				centered:true,
				confirmLoading:self.confirmLoading,
				onOk() {
					self.confirmedAccept()
				}
			});
        },

        confirmedAccept(){
            this.confirmLoading = true;

            this.$api.put('/user-invites/accept/'+this.confirm.id).then( ({data}) => {

                this.$message.success('Congrats! You now have access to ' + this.confirm.instance.name,3);
                this.showConfirm = false;

				this.updateInvitations(this.confirm.id)
                this.$emit('updateInvitations', this.confirm.id, data.instance)

            }).catch( () => this.$message.error('Error occurred while accepting invitation. Please try again!') )
		},

		async decline({instance,role,id,email, declineToken}){
			this.confirm = {instance,role,id,email, declineToken}
            this.confirm.accept = true
			let self = this
			this.$confirm({
				title: 'Declining Invitation',
				content: h => <div class='mt-4'>This will decline the invitation to <strong>{instance.name}</strong> with the role of {this.$getRoleName(role.name)}. Are you sure?</div>,
				okText: 'OK',
				okType: 'danger',
				cancelText: 'Cancel',
				centered:true,
				confirmLoading:self.confirmLoading,
				onOk() {
					self.confirmedDecline()
				}
			});
		},

		confirmedDecline(){
			this.confirmLoading = true;

            this.$api.get('/user-invites/decline/'+this.confirm.declineToken).then( () => {

                this.$message.warn(`You have declined the invitation for ${this.confirm.instance.name}.`);
                this.showConfirm = false

				this.updateInvitations(this.confirm.id)
                this.$emit('updateInvitations', this.confirm.id)

            }).catch((error) => {
				console.error('Error while declining invitation', error)
				this.$message.error('Error occurred while declining invitation. Please try again!')
			})
		},

		updateInvitations(id){
			let invitationList = [...this.invitations];

			let index = invitationList.findIndex((x) => x.id == id);
            if (index != -1) {
                invitationList.splice(index, 1);
				this.$store.commit("SET_INVITATIONS", invitationList);
            }
		}
    },
    async created() {

    }
}
</script>

<style scoped lang="scss">

</style>
<style scoped>

</style>
<style>
    .popover-padding .ant-popover-content .ant-popover-inner .ant-popover-inner-content{
        padding: 0 !important;
    }
    .popover-content{
        padding:10px;
        cursor:pointer;
        border-radius: 4px;
    }
    .popover-content:hover{
        background-color: var(--orange);
        color:#FFF;
    }
</style>
