var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"Colors"},on:{"ok":_vm.handleColorChange},model:{value:(_vm.colourModal.visible),callback:function ($$v) {_vm.$set(_vm.colourModal, "visible", $$v)},expression:"colourModal.visible"}},[_c('div',{staticClass:"dF aC",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.colourList),function(color,colorI){return _c('div',{key:color + colorI,staticClass:"px-1 py-1",attrs:{"value":color}},[_c('div',{staticClass:"relative",staticStyle:{"border-radius":"50%","height":"20px","width":"20px","cursor":"pointer"},style:({ 'background-color': color }),on:{"click":function($event){_vm.selectedColor = color}}},[(_vm.selectedColor === color)?_c('div',{staticClass:"absolute",staticStyle:{"width":"100%","height":"100%","display":"flex","align-items":"center","justify-content":"center"}},[_c('i',{staticClass:"fe fe-check"})]):_vm._e()])])}),0)]),(_vm.type == 'recent' && _vm.instances.length && _vm.instances.length > 0)?_vm._l((_vm.instances),function(inst,instI){return _c('div',{key:`instances${instI}`,staticClass:"dF aC instance-row",staticStyle:{"height":"91px","border-bottom":"2px solid #d8d8d8"}},[_c('div',{staticClass:"px-4 dF aC",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"icon-option",class:_vm.$mq != 'sm' ? 'mx-4' : 'mr-2'},[_c('div',{staticClass:"hide-hexagon hexagon hexagon2"},[_c('div',{staticClass:"hexagon-in1"},[_c('div',{staticClass:"hexagon-in2",style:({
                                        'background-color': _vm.getBackground(inst),
                                    })})])]),_c('a-popover',{attrs:{"trigger":"click","placement":"bottomLeft","overlayClassName":"remove-padding"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.pinInstance(inst.id)}}},[_vm._v(" "+_vm._s(!_vm.pin.includes(inst.id) ? "Pin" : "Unpin")+" ")]),(_vm.$userType !== 'agent')?_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.colourInstance(inst)}}},[_vm._v(" Color ")]):_vm._e(),(_vm.$userType !== 'agent')?_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.settingInstance(inst.id)}}},[_vm._v(" Settings ")]):_vm._e()]),_c('div',{staticClass:"option-button",attrs:{"tabindex":"1"}},[_c('i',{staticClass:"fe fe-more-vertical",staticStyle:{"font-size":"25px","cursor":"pointer"},style:({ color: _vm.getBackground(inst) })})])])],1),_c('router-link',{staticClass:"dF aC",staticStyle:{"width":"100%","height":"100%","color":"#000"},attrs:{"to":`/select/${inst.id}`}},[_c('div',{staticClass:"f1 mr-2"},[_c('div',{staticClass:"dF aC"},[_c('div',[_vm._v(_vm._s(inst.name))]),(_vm.pin.includes(inst.id))?_c('i',{staticClass:"fa fa-star",class:_vm.$mq == 'sm' ? 'ml-2' : 'ml-3',staticStyle:{"color":"#fed762"},style:(`font-size:${
                                        _vm.$mq === 'sm' ? '13' : '24'
                                    }px`)}):_vm._e()]),_c('div',{staticStyle:{"color":"#757575"}},[_vm._v(" "+_vm._s(!inst.children || inst.children.length == 0 ? "" : inst.children.length == 1 ? `${inst.children.length} instance connected` : `${inst.children.length} projects linked`)+" ")])]),_c('div',{staticClass:"dF aC instance-glance"},[(_vm.$mq !== 'sm')?_c('div',{staticClass:"mr-3 f1",staticStyle:{"color":"#757575","width":"116px"}},[_vm._v(" "+_vm._s(_vm.lastVisited[inst.id] ? _vm.convertDate(_vm.lastVisited[inst.id]) : "")+" ")]):_vm._e(),_c('div',{style:(_vm.$mq != 'sm' ? 'width:110px' : 'width:53px')},[_c('a-tooltip',{attrs:{"slot":"prefix","title":inst.own ? 'Owned' : 'Shared with you',"overlayClassName":"change-tooltip-color"},slot:"prefix"},[(inst.own)?_c('i',{staticClass:"fe fe-user",staticStyle:{"color":"#d0c9d6"}}):_c('i',{staticClass:"fe fe-users",staticStyle:{"color":"#d0c9d6"}})]),(_vm.linkedInstances.includes(inst.id))?_c('a-tooltip',{class:_vm.$mq != 'sm' ? 'ml-3' : 'ml-2',attrs:{"slot":"prefix","title":"Linked","overlayClassName":"change-tooltip-color"},slot:"prefix"},[_c('i',{staticClass:"fe fe-link",staticStyle:{"color":"#d0c9d6"}})]):_vm._e(),(
                                        inst.children &&
                                        inst.children.length > 0
                                    )?_c('a-tooltip',{class:_vm.$mq != 'sm' ? 'ml-3' : 'ml-2',attrs:{"slot":"prefix","title":"Parent Project","overlayClassName":"change-tooltip-color"},slot:"prefix"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 20.4 16.32","fill":"#FF9027"}},[_c('path',{staticClass:"cls-1",attrs:{"id":"Icon_awesome-crown","data-name":"Icon awesome-crown","d":"M16.83,14.28H3.57a.511.511,0,0,0-.51.51v1.02a.511.511,0,0,0,.51.51H16.83a.511.511,0,0,0,.51-.51V14.79A.511.511,0,0,0,16.83,14.28Zm2.04-10.2a1.53,1.53,0,0,0-1.53,1.53,1.5,1.5,0,0,0,.14.631L15.172,7.624a1.019,1.019,0,0,1-1.409-.37l-2.6-4.545a1.53,1.53,0,1,0-1.932,0l-2.6,4.545a1.019,1.019,0,0,1-1.409.37l-2.3-1.383a1.529,1.529,0,1,0-1.393.9,1.562,1.562,0,0,0,.245-.025l2.3,6.145H16.32l2.3-6.145a1.562,1.562,0,0,0,.245.025,1.53,1.53,0,1,0,0-3.06Z"}})])]):_vm._e()],1)])])],1)])}):_vm._e(),(_vm.type != 'recent' && _vm.instances.length && _vm.instances.length > 0)?_c('draggable',_vm._b({attrs:{"list":_vm.instances,"delay-on-touch-only":true,"delay":100},on:{"change":(e) => _vm.onChange(_vm.instances, e),"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false}}},'draggable',_vm.dragOptions,false),_vm._l((_vm.instances),function(inst,instI){return _c('div',{key:`instances${instI}`,staticClass:"dF aC instance-row",staticStyle:{"height":"91px","border-bottom":"2px solid #d8d8d8"}},[_c('div',{staticClass:"px-4 dF aC",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"icon-option",class:_vm.$mq != 'sm' ? 'mx-4' : 'mr-2'},[_c('div',{staticClass:"hide-hexagon hexagon hexagon2"},[_c('div',{staticClass:"hexagon-in1"},[_c('div',{staticClass:"hexagon-in2",style:({
                                        'background-color': _vm.getBackground(inst),
                                    })})])]),_c('a-popover',{attrs:{"trigger":"click","placement":"bottomLeft","overlayClassName":"remove-padding"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[(_vm.page != 'project')?_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.pinInstance(inst.id)}}},[_vm._v(" "+_vm._s(!_vm.pin.includes(inst.id) ? "Pin" : "Unpin")+" ")]):_vm._e(),(_vm.$userType !== 'agent')?_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.colourInstance(inst)}}},[_vm._v(" Color ")]):_vm._e(),(_vm.$userType !== 'agent')?_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.settingInstance(inst.id)}}},[_vm._v(" "+_vm._s(_vm.page == "project" ? "Edit Project" : "Settings")+" ")]):_vm._e(),(inst.own)?_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.billingInstance(inst.id)}}},[_vm._v(" Billing ")]):_vm._e(),(_vm.page == 'project' && inst.own)?_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.$emit('deleteInstance', inst.id)}}},[_vm._v(" Delete Project ")]):_vm._e()]),_c('div',{staticClass:"option-button",attrs:{"tabindex":"0"}},[_c('i',{staticClass:"fe fe-more-vertical",staticStyle:{"font-size":"25px","cursor":"pointer"},style:({ color: _vm.getBackground(inst) })})])])],1),_c('router-link',{staticClass:"dF aC",staticStyle:{"width":"100%","height":"100%","color":"#000"},attrs:{"to":`/select/${inst.id}`}},[_c('div',{staticClass:"f1"},[_c('div',{staticClass:"dF aC"},[_c('div',[_vm._v(_vm._s(inst.name))]),(_vm.pin.includes(inst.id))?_c('i',{staticClass:"fa fa-star",class:_vm.$mq == 'sm' ? 'ml-2' : 'ml-3',staticStyle:{"color":"#fed762"},style:(`font-size:${
                                        _vm.$mq === 'sm' ? '13' : '24'
                                    }px`)}):_vm._e(),(inst.isDemo)?_c('a-tag',{staticClass:"ml-3"},[_vm._v(" Demo ")]):_vm._e()],1),_c('div',{staticStyle:{"color":"#757575"}},[_vm._v(" "+_vm._s(!inst.children || inst.children.length == 0 ? "" : inst.children.length == 1 ? `${inst.children.length} project linked` : `${inst.children.length} projects linked`)+" ")])]),_c('div',{staticClass:"dF aC instance-glance"},[(_vm.$mq !== 'sm')?_c('div',{staticClass:"mr-3 f1",staticStyle:{"color":"#757575","width":"116px"}},[_vm._v(" "+_vm._s(_vm.lastVisited[inst.id] ? _vm.convertDate(_vm.lastVisited[inst.id]) : "")+" ")]):_vm._e(),_c('div',{style:(_vm.$mq != 'sm' ? 'width:110px' : 'width:53px')},[_c('a-tooltip',{attrs:{"slot":"prefix","title":inst.own ? 'Owned' : 'Shared with you',"overlayClassName":"change-tooltip-color"},slot:"prefix"},[(inst.own)?_c('i',{staticClass:"fe fe-user",staticStyle:{"color":"#d0c9d6"}}):_c('i',{staticClass:"fe fe-users",staticStyle:{"color":"#d0c9d6"}})]),(_vm.linkedInstances.includes(inst.id))?_c('a-tooltip',{class:_vm.$mq == 'sm' ? 'ml-2' : 'ml-3',attrs:{"slot":"prefix","title":"Linked","overlayClassName":"change-tooltip-color"},slot:"prefix"},[_c('i',{staticClass:"fe fe-link",staticStyle:{"color":"#d0c9d6"}})]):_vm._e(),(
                                        inst.children &&
                                        inst.children.length > 0
                                    )?_c('a-tooltip',{class:_vm.$mq == 'sm' ? 'ml-2' : 'ml-3',attrs:{"slot":"prefix","title":"Parent Project","overlayClassName":"change-tooltip-color"},slot:"prefix"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 20.4 16.32","fill":"#FF9027"}},[_c('path',{staticClass:"cls-1",attrs:{"id":"Icon_awesome-crown","data-name":"Icon awesome-crown","d":"M16.83,14.28H3.57a.511.511,0,0,0-.51.51v1.02a.511.511,0,0,0,.51.51H16.83a.511.511,0,0,0,.51-.51V14.79A.511.511,0,0,0,16.83,14.28Zm2.04-10.2a1.53,1.53,0,0,0-1.53,1.53,1.5,1.5,0,0,0,.14.631L15.172,7.624a1.019,1.019,0,0,1-1.409-.37l-2.6-4.545a1.53,1.53,0,1,0-1.932,0l-2.6,4.545a1.019,1.019,0,0,1-1.409.37l-2.3-1.383a1.529,1.529,0,1,0-1.393.9,1.562,1.562,0,0,0,.245-.025l2.3,6.145H16.32l2.3-6.145a1.562,1.562,0,0,0,.245.025,1.53,1.53,0,1,0,0-3.06Z"}})])]):_vm._e()],1)])])],1)])}),0):_vm._e(),(_vm.type == 'invitation')?void 0:_vm._e(),(!_vm.instances.length || _vm.instances.length == 0)?_c('div',{staticClass:"align-center mt-5"},[_c('img',{staticStyle:{"width":"100%","max-width":"500px"},attrs:{"src":require("@/assets/noinstance_found.svg")}}),_c('h3',{staticClass:"mt-5"},[_vm._v("No Project Found")])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }